/**
 * КШД Интегратор
 * API конфигуратора
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdapterDto } from './adapterDto';
import { LoggerDto } from './loggerDto';
import { OutgoingAdapterDto } from './outgoingAdapterDto';
import { TranslatorDto } from './translatorDto';
import { IncomingAdapterDto } from './incomingAdapterDto';


export interface ServiceDto { 
    status?: ServiceDto.StatusEnum;
    isEnabled?: boolean;
    isTemplate?: boolean;
    hasIngress?: boolean;
    period?: number;
    timeoutOnError?: number;
    stopOnError?: boolean;
    logLevel?: string;
    loggers?: Array<LoggerDto>;
    incomingAdapters?: Array<IncomingAdapterDto>;
    translators?: Array<TranslatorDto>;
    errorAdapter?: AdapterDto;
    outgoingAdapters?: Array<OutgoingAdapterDto>;
    processorUuid?: string;
    routerUuid?: string;
    uuid?: string;
    title?: string;
    name?: string;
    description?: string;
    options?: object;
    archivedAt?: string;
    archivedBy?: string;
    createdAt?: string;
    createdBy?: string;
    updatedAt?: string;
    updatedBy?: string;
}
export namespace ServiceDto {
    export type StatusEnum = 'unavailable' | 'disabled' | 'started' | 'running' | 'shutdown' | 'stopped' | 'error';
    export const StatusEnum = {
        Unavailable: 'unavailable' as StatusEnum,
        Disabled: 'disabled' as StatusEnum,
        Started: 'started' as StatusEnum,
        Running: 'running' as StatusEnum,
        Shutdown: 'shutdown' as StatusEnum,
        Stopped: 'stopped' as StatusEnum,
        Error: 'error' as StatusEnum
    };
}


