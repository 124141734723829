/**
 * КШД Интегратор
 * API конфигуратора
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MenuItemData } from './menuItemData';


/**
 * Элемент иерархического меню
 */
export interface MenuItem { 
    key?: string;
    label?: string;
    type?: MenuItem.TypeEnum;
    icon?: string;
    data?: MenuItemData;
    children?: Array<MenuItem>;
    childrenType?: MenuItem.ChildrenTypeEnum;
}
export namespace MenuItem {
    export type TypeEnum = 'adapters-group' | 'incoming-adapters-group' | 'outgoing-adapters-group' | 'adapters' | 'incoming-adapters' | 'outgoing-adapters' | 'routers-group' | 'channels' | 'channels-group' | 'commands' | 'commands-group' | 'conditions' | 'conditions-group' | 'credentials' | 'credentials-group' | 'loggers' | 'loggers-group' | 'processors' | 'processors-group' | 'projects' | 'projects-group' | 'services' | 'services-group' | 'routers' | 'translators' | 'translators-group';
    export const TypeEnum = {
        AdaptersGroup: 'adapters-group' as TypeEnum,
        IncomingAdaptersGroup: 'incoming-adapters-group' as TypeEnum,
        OutgoingAdaptersGroup: 'outgoing-adapters-group' as TypeEnum,
        Adapters: 'adapters' as TypeEnum,
        IncomingAdapters: 'incoming-adapters' as TypeEnum,
        OutgoingAdapters: 'outgoing-adapters' as TypeEnum,
        RoutersGroup: 'routers-group' as TypeEnum,
        Channels: 'channels' as TypeEnum,
        ChannelsGroup: 'channels-group' as TypeEnum,
        Commands: 'commands' as TypeEnum,
        CommandsGroup: 'commands-group' as TypeEnum,
        Conditions: 'conditions' as TypeEnum,
        ConditionsGroup: 'conditions-group' as TypeEnum,
        Credentials: 'credentials' as TypeEnum,
        CredentialsGroup: 'credentials-group' as TypeEnum,
        Loggers: 'loggers' as TypeEnum,
        LoggersGroup: 'loggers-group' as TypeEnum,
        Processors: 'processors' as TypeEnum,
        ProcessorsGroup: 'processors-group' as TypeEnum,
        Projects: 'projects' as TypeEnum,
        ProjectsGroup: 'projects-group' as TypeEnum,
        Services: 'services' as TypeEnum,
        ServicesGroup: 'services-group' as TypeEnum,
        Routers: 'routers' as TypeEnum,
        Translators: 'translators' as TypeEnum,
        TranslatorsGroup: 'translators-group' as TypeEnum
    };
    export type ChildrenTypeEnum = 'adapters-group' | 'incoming-adapters-group' | 'outgoing-adapters-group' | 'adapters' | 'incoming-adapters' | 'outgoing-adapters' | 'routers-group' | 'channels' | 'channels-group' | 'commands' | 'commands-group' | 'conditions' | 'conditions-group' | 'credentials' | 'credentials-group' | 'loggers' | 'loggers-group' | 'processors' | 'processors-group' | 'projects' | 'projects-group' | 'services' | 'services-group' | 'routers' | 'translators' | 'translators-group';
    export const ChildrenTypeEnum = {
        AdaptersGroup: 'adapters-group' as ChildrenTypeEnum,
        IncomingAdaptersGroup: 'incoming-adapters-group' as ChildrenTypeEnum,
        OutgoingAdaptersGroup: 'outgoing-adapters-group' as ChildrenTypeEnum,
        Adapters: 'adapters' as ChildrenTypeEnum,
        IncomingAdapters: 'incoming-adapters' as ChildrenTypeEnum,
        OutgoingAdapters: 'outgoing-adapters' as ChildrenTypeEnum,
        RoutersGroup: 'routers-group' as ChildrenTypeEnum,
        Channels: 'channels' as ChildrenTypeEnum,
        ChannelsGroup: 'channels-group' as ChildrenTypeEnum,
        Commands: 'commands' as ChildrenTypeEnum,
        CommandsGroup: 'commands-group' as ChildrenTypeEnum,
        Conditions: 'conditions' as ChildrenTypeEnum,
        ConditionsGroup: 'conditions-group' as ChildrenTypeEnum,
        Credentials: 'credentials' as ChildrenTypeEnum,
        CredentialsGroup: 'credentials-group' as ChildrenTypeEnum,
        Loggers: 'loggers' as ChildrenTypeEnum,
        LoggersGroup: 'loggers-group' as ChildrenTypeEnum,
        Processors: 'processors' as ChildrenTypeEnum,
        ProcessorsGroup: 'processors-group' as ChildrenTypeEnum,
        Projects: 'projects' as ChildrenTypeEnum,
        ProjectsGroup: 'projects-group' as ChildrenTypeEnum,
        Services: 'services' as ChildrenTypeEnum,
        ServicesGroup: 'services-group' as ChildrenTypeEnum,
        Routers: 'routers' as ChildrenTypeEnum,
        Translators: 'translators' as ChildrenTypeEnum,
        TranslatorsGroup: 'translators-group' as ChildrenTypeEnum
    };
}


